<template>
  <div class="content">
    <Header/>
    <div class="adminList">
      <ul v-for="(item, key) in adminsList" :key="key" class="flex">
        <li>{{ item.name }}</li>
        <li>{{ item.login }}</li>
        <li>
          <a
            href="#!"
            @click.prevent="edit"
            :data-id="key"
            class="ico-edit"
          ></a>
        </li>
        <li>
          <a href="#!" @click.prevent="del" :data-id="key" class="ico-del"></a>
        </li>
      </ul>

      <div class="form ui flex fWrap">
        <div class="row col100">
          <span>ФИО</span>
          <input type="text" v-model="editAdminName" />
        </div>
        <div class="row col50">
          <span>Login</span>
          <input type="text" v-model="editAdminLogin" />
        </div>
        <div class="row col50">
          <span>Password</span>
          <input type="text" v-model="editAdminPass" />
        </div>
        <div class="row">
          <a href="#!" @click="editAdmin" class="btn">Сохранить</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/components/app/api";
import Helper from "@/components/app/helper";
import Header from "@/components/app/header";
//import Config from "@/components/app/config";
export default {
  name: "Home",
  data() {
    return {
      adminsList: [], //Список администраторов
      editAdminName: "",
      editAdminLogin: "",
      editAdminPass: "",
      editAdminId: 0,
    };
  },
  watch: {},
  computed: {},
  methods: {
    async getList() {
      this.adminsList = await api.methods.ADMINS_getList();
    },
    edit(e) {
      let key = e.target.dataset.id;
      this.editAdminName = this.adminsList[key].name;
      this.editAdminLogin = this.adminsList[key].login;
      this.editAdminId = this.adminsList[key].id;
    },
    async del(e) {
      if (window.confirm("Удаляем администратора?")) {
        let key = e.target.dataset.id;
        let response = await api.methods.ADMINS_del({
          id: this.adminsList[key].id,
        });
        Helper.methods.warning(response, "ok");
        await this.getList();
      }
    },
    async editAdmin() {
      //Добавляем / изменяем администратора
      console.log(Helper.methods.isEmptyStr(this.editAdminName));
      if (Helper.methods.isEmptyStr(this.editAdminName)) {
        Helper.methods.warning("Поле имя не должно быть пустым", "error");
        return;
      }
      if (Helper.methods.isEmptyStr(this.editAdminLogin)) {
        Helper.methods.warning("Поле логин не должно быть пустым", "error");
        return;
      }
      if (Helper.methods.isEmptyStr(this.editAdminPass) && this.editAdminId==0) {
        Helper.methods.warning("Поле пароль не должно быть пустым", "error");
        return;
      }
      let response = await api.methods.ADMINS_add({
        name: this.editAdminName,
        login: this.editAdminLogin,
        pass: this.editAdminPass,
        id: this.editAdminId,
      });
      this.editAdminName = "";
      this.editAdminLogin = "";
      this.editAdminPass = "";
      this.editAdminId = "";

      Helper.methods.warning(response, "ok");
      await this.getList();
    },
  },
  async mounted() {
    await this.getList();
  },
  async created() {},
  components: {
    Header
  },
};
</script>
